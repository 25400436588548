import LoadingAnim from "../images/loading-anim.svg";

function Loading() {
  return (
    <div className="flex flex-col justify-center items-center h-screen">
      <img src={LoadingAnim} alt="loading" className="w-24 h-24" />
      <p className="text-gray-400 text-sm mt-4">loading</p>
    </div>
  );
}

export default Loading;
